import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputField from "../common/components/inputfield/InputField";
import addIcon from "../common/assets/images/add_circle.svg";
import DatePicker from "react-datepicker";
import moment from "moment";
import wrong from "../common/assets/images/wrong.svg";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { useMutation, useQuery } from "react-query";
import { Navigate, useLocation, useNavigate } from "react-router";
import AlertModal from "../common/components/popupmodel/AlertModal";
import CreatableSelect from "react-select/creatable"; // Correct import for Creatable

export const getViewRequest = async () => {
  const response = await axios.get(
    // `https://api.2kvirtualworld.com/CRM/requests`
    `${process.env.REACT_APP_HOST}/requests`
  );
  return response.data; 
};

const options = [
  { value: "With Material", label: "With Material" },
  { value: "Without Material", label: "Without Material" },
];

export default function AddRequest() {
  const [base64Image, setBase64Image] = useState({});
  const [deleteConfirmAlert, setDeleteConfirmAlert] = useState(null);
  const [deleteSuccessfully, setDeleteSuccessfully] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [fetchedData, setFetchedData] = useState(null);
  const [clrData, setClrData] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(false);

  const [modelOptions, setModelOptions] = useState([]);
  const [issueOptions, setIssueOptions] = useState([]);
  const [productData, setProductData] = useState([]);
  const [modelData, setModelData] = useState({});
  const [issueMapping, setIssueMapping] = useState({});
  const [brandMapping, setBrandMapping] = useState({});
  const [modelMapping, setModelMapping] = useState({});

  const [selected, setSelected] = useState("Yes");

  const [serviceselected, setServiceSelected] = useState("MaterialYes");

  const handleCheckboxChange = (value) => {
    setSelected(selected === value ? "" : value);
  };
  const handleCheckboxChanges = (value) => {
    setServiceSelected(serviceselected === value ? "" : value);
  };

  // Function to get the current system time in HH:mm format
  const getCurrentTime = () => {
    const now = new Date();
    return now.toTimeString().slice(0, 5); // Format time as HH:mm (e.g., "14:30")
  };

  const [currentTime, setCurrentTime] = useState(getCurrentTime());
  const { control, handleSubmit, setValue, reset, watch, getValues } = useForm({
    defaultValues: {
      appointmentTime: currentTime,
      selectSlot: currentTime,
      amcTime: currentTime,
      registerDate: new Date(),
    },
  });
  const [formData, setFormData] = useState("");

  const getTechDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST}/assignTechnicianRequest/request/${state?.requestID}`
      );
      return response?.data;
    } catch (error) {
      if (!error.response) {
        // Handle network error (No response from server)
        throw new Error(
          "Network error! Please check your internet connection."
        );
      }
      throw new Error(error.response?.data?.message || "Something went wrong!");
    }
  };

  const { data: techpercentage, error } = useQuery(
    "getTechDetails",
    getTechDetails,
    {
      enabled: !!state?.requestID,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
      retry: 1, // Only retry once if the request fails
      onError: (error) => {
        alert(error.message); // Show an alert with the error message
      },
    }
  );
  let techpercent = techpercentage?.assignedTechnician?.technicianPercentage;
  let materialpercent = techpercentage?.assignedTechnician?.materialPercentage;

  const finalTechPercentage = selected === "Yes" ? techpercent : 0;

  const finalMaterialPercentage =
    serviceselected == "MaterialYes" ? materialpercent : 0;


  // Update time every minute
  useEffect(() => {
    const interval = setInterval(() => {
      const newTime = getCurrentTime();
      setCurrentTime(newTime);
      setValue("appointmentTime", newTime);
      setValue("selectSlot", newTime);
    }, 60000);

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [setValue]);

  const isChecked = watch("amc", false); // Default is false (unchecked)
  const amcStartDate = watch("amcDate");
  const amcYear = watch("amcYear");

  // File  Upload Start
  const handleImageInputChange = (event, fileName) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result;
        const base64LetterRemove = base64String.substring(
          base64String.indexOf(",") + 1
        );
        // setBase64Image(base64LetterRemove);
        setBase64Image({ ...base64Image, [fileName]: base64LetterRemove });
      };
      reader.onerror = (error) => {
        console.error(
          "Error occurred while converting image to base64:",
          error
        );
      };
    }
  };
  const removeKey = (fileName) => {
    setBase64Image({ ...base64Image, [fileName]: "" });
  };
// File Upload End


  const {
    product,
    mobileNo,
    alternateNo,
    clearPayment,
    clearData,
    productCharge,
    balanceAmount,
    actualAmount,
    currentAmount,
    replaceMaterialCharge,
    serviceCharge,
    purchaseMaterialCost,
    transportKM,
    transportAmount,
    totalTechnicianShare,
    transportCharges,
    totalAmt,
    technicianShare,
    otherCharges,
  } = watch();
  const [isTechnicianDataSet, setIsTechnicianDataSet] = useState(false);
  // service percentage

  // Material percentage

  let materialcharge =
    Number(replaceMaterialCharge) - Number(purchaseMaterialCost);

  let techniciancharge =
    (Number(serviceCharge) * Number(finalTechPercentage)) / 100;

  let ownercharge = Number(serviceCharge) - Number(techniciancharge);

  let technicianchargetwo =
    (Number(materialcharge) * Number(finalMaterialPercentage)) / 100;

  let ownerchargetwo = Number(materialcharge) - Number(technicianchargetwo);

  let totaltechniciancharge =
    Number(techniciancharge) + Number(technicianchargetwo);

  let totalownercharge = Number(ownercharge) + Number(ownerchargetwo);

  let transportAmt = Number(transportKM) * Number(transportAmount);

  let totaltechnicianshares =
    Number(totaltechniciancharge) + Number(otherCharges) + Number(transportAmt);

  // calculation for technician owner percentage

  useEffect(() => {
    setValue("transportCharges", Number(transportAmt && transportAmt) || 0);
    setValue(
      "technicianShare",
      Number(totaltechniciancharge) ||
        Number(technicianchargetwo) ||
        Number(techniciancharge) ||
        0
    );
    setValue(
      "ownerShare",
      Number(totalownercharge) ||
        Number(ownerchargetwo) ||
        Number(ownercharge) ||
        0
    );
    setValue("totalTechnicianShare", Number(totaltechnicianshares) || 0);
  }, [
    setValue,
    transportAmt,
    transportAmount,
    transportKM,
    otherCharges,
    totaltechnicianshares,
    finalMaterialPercentage,
    finalTechPercentage,
    materialcharge,
    techniciancharge,
    ownercharge,
    technicianchargetwo,
    ownerchargetwo,
    totaltechniciancharge,
    totalownercharge,
    serviceCharge,
    replaceMaterialCharge,
    purchaseMaterialCost,
  ]);

  // calculation for payment details

  let totalAMOUNT = Number(replaceMaterialCharge) + Number(serviceCharge);

  let balancemoney = Number(totalAMOUNT) - Number(currentAmount);

  let amtpaid = Number(state?.currentAmount) + Number(actualAmount);

  useEffect(() => {
    setValue("totalAmt", Number(totalAMOUNT) || null);

    setValue("currentAmount", Number(amtpaid) || null);

    if (Number(totalAmt) == Number(currentAmount)) {
      setValue("balanceAmount", 0 || null);
    }
    setValue("balanceAmount", Number(balancemoney) || null);

    setValue(
      "paymentStatus",
      totalAmt == currentAmount ? "Completed" : "Pending"
    );
  }, [
    state,
    totalAMOUNT,
    balancemoney,
    actualAmount,
    currentAmount,
    state?.currentAmount,
    totalAmt,
    amtpaid,
  ]);

  useEffect(() => {
    if (state && state) {
      setBase64Image({
        productImg: state?.productImage,
        modelImg: state?.modelImage,
        serialImg: state?.serialImage,
      });
      setValue("firstName", state && state?.firstName);
      setValue("lastName", state?.lastName);
      setValue("address", state?.address);
      setValue("mobileNo", state?.mobileNo);
      setValue("alternateNo", state?.alternateNo);
      setValue("product", state?.product);
      setValue("modelName", state?.modelName);
      setValue("issue", state?.issue);
      setValue("serviceName", state?.serviceName);
      setValue("selectSlot", state?.selectSlot);
      setValue("totalAmt", state?.totalAmount);
      setValue("netAmt", state?.netAmount);
      setValue("technicianShare", state?.technicianShare);
      setValue("ownerShare", state?.ownerShare);
      setValue("place", state?.place);
      setValue("pincode", state?.pincode);
      setValue("referenceName", state?.referenceName);
      setValue("brandName", state?.brandName);
      setValue("serialNo", state?.serialNo);
      setValue("replaceMaterialName", state?.replaceMaterialName);
      setValue("replaceMaterialCharge", state?.replaceMaterialCharge);
      setValue("referencePhoneNumber", state?.referencePhoneNumber);
      setValue("appointmentDate", state?.appointmentDate);
      setValue("date", state?.date);
      setValue("appointmentTime", state?.appointmentTime);
      setValue("purchaseMaterialCost", state?.purchaseMaterialCost);
      setValue("transportCharges", state?.transportCharges);
      setValue("otherCharges", state?.otherCharges);
      setValue("transportKM", state?.transportKM);
      setValue("transportAmount", state?.transportAmount);
      setValue("serviceCharge", state?.serviceCharge);
      setValue("otherDetails", state?.otherDetails);
      setValue("totalTechnicianShare", state?.totalTechnicianShare);
      setValue("amcCharge", state?.amcCharge || "");
      setValue("amcDate", state?.amcDate || "");
      setValue("endDate", state?.endDate || "");
      setValue("amcTime", state?.amcTime || "");
      setValue("amcYear", state?.amcYear || "");
      setValue("amc", state?.amc || "");
      setValue("warrantyDetails", state?.warrantyDetails || "");
      setValue("modelType", state?.modelType || "");
      setValue("productCharge", state?.productCharge || "");
      setValue("productcapacity", state?.productcapacity);
    }
    setValue("serviceName", product);
    setValue("amcCharge", productCharge);

    if (amcStartDate && amcYear) {
      const startDate = moment(amcStartDate);
      const endDate = startDate
        ?.add(parseInt(amcYear), "years")
        .subtract(1, "days");

      setValue("endDate", endDate?.toDate());
    }
  }, [state, setValue, product, amcStartDate, amcYear, productCharge]);

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (postData) => {
      try {
        console.log("postData", postData);

        const response = await axios.post(
          `${process.env.REACT_APP_HOST}/requests`,
          postData
        );
        // setConfirmAlert(true);
        setDeleteConfirmAlert(false);
        navigate("/");
        // deleteConfirmAlert(true);
        return response.data;
      } catch (error) {
        console.error("Error in mutation:", error);

        throw error;
      }
    }
  );

  const UpdateFormValue = useMutation(
    (postData) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/requests/edit/${state?.requestID}`,
        postData
      ),
    {
      onSuccess: (data) => {
        setDeleteConfirmAlert(false);
        navigate("/");
        // deleteConfirmAlert(true);
        // Navigate("/viewUserManagement");
      },
      onError: (error) => {
        // alert(error)
        console("Mutation failed:", error);
      },
    }
  );

  const { data, refetch } = useQuery("getViewRequest", getViewRequest, {
    enabled: false,
  });

  const getModelList = async () => {
    const response = await axios.get(`${process.env.REACT_APP_HOST}/products`);
    return response.data; // Ensure we return the actual data from the response
  };

  // Fetch data using useEffect
  useEffect(() => {
    const fetchData = async () => {
      const data = await getModelList();
      setProductData(data?.map((d) => d?.product)); // Extract products
      const modelMapping = data?.reduce((acc, cur) => {
        acc[cur?.product] = cur?.subProduct || [];
        return acc;
      }, {});
      const issueMapping = data?.reduce((acc, cur) => {
        acc[cur?.product] = cur?.complaint || [];
        return acc;
      }, {});
      const brandMapping = data?.reduce((acc, cur) => {
        acc[cur?.product] = cur?.brandName || "";
        return acc;
      }, {});
      const modelNameMapping = data?.reduce((acc, cur) => {
        acc[cur?.product] = cur?.modelName || "";
        return acc;
      }, {});
      setModelData(modelMapping);
      setIssueMapping(issueMapping);
      setBrandMapping(brandMapping); // Store the brand name mapping
      setModelMapping(modelNameMapping);
    };
    fetchData();
  }, []);

  // Handle product selection change
  const handleProductChange = (selectedProduct) => {
    const models = modelData[selectedProduct] || [];
    const issues = issueMapping[selectedProduct] || [];

    const modelOptions = models?.map((model) => ({
      label: model,
      value: model,
    }));
    const issueOptions = issues?.map((issue) => ({
      label: issue,
      value: issue,
    }));

    setModelOptions(modelOptions);
    setIssueOptions(issueOptions); // Reset issues based on selected product
    setValue("modelType", ""); // Reset model selection
    setValue("complaint", ""); // Reset issue selection

    // Set the brandName based on the selected product
    const brandName = brandMapping[selectedProduct] || "";
    setValue("brandName", brandName);

    // modelName based on select product
    const modelName = modelMapping[selectedProduct] || "";
    setValue("modelName", modelName);
  };

  useEffect(() => {
    if ((mobileNo || alternateNo) && !isTechnicianDataSet) {
      refetch(); // Fetch technician data when numbers are entered
    }
  }, [mobileNo, alternateNo, refetch, isTechnicianDataSet]);

  useEffect(() => {
    if (data && (mobileNo || alternateNo) && !isTechnicianDataSet) {
      const details = data?.find(
        (item) =>
          Number(item?.mobileNo) === Number(mobileNo) ||
          Number(item?.alternateNo) === Number(alternateNo)
      );
      if (details) {
        setFetchedData(details);
        setBase64Image({
          productImg: details?.productImage,
          modelImg: details?.modelImage,
          serialImg: details?.serialImage,
        });
        setValue("firstName", details?.firstName || null);
        setValue("lastName", details?.lastName || null);
        setValue("address", details?.address || null);
        setValue("mobileNo", details?.mobileNo || null);
        setValue("alternateNo", details?.alternateNo || null);
        setValue("product", details?.product || null);
        setValue("modelName", details?.modelName || null);
        setValue("issue", details?.issue || null);
        setValue("serviceName", details?.serviceName || null);
        setValue("selectSlot", details?.selectSlot || null);
        setValue("totalAmt", details?.totalAmount || null);
        setValue("productCharge", details?.productCharge || null);
        // setValue("actualAmount", details?.actualAmount || null);
        setValue("netAmt", details?.netAmount || null);
        setValue("technicianShare", details?.technicianShare || null);
        setValue("ownerShare", details?.ownerShare || null);
        setValue("place", details?.place || null);
        setValue("pincode", details?.pincode || null);
        setValue("referenceName", details?.referenceName || null);
        setValue("brandName", details?.brandName || null);
        setValue("serialNo", details?.serialNo || null);
        setValue("replaceMaterialName", details?.replaceMaterialName || null);
        setValue(
          "replaceMaterialCharge",
          details?.replaceMaterialCharge || null
        );
        setValue("referencePhoneNumber", details?.referencePhoneNumber || null);
        setValue("appointmentDate", details?.appointmentDate || null);
        setValue("appointmentTime", details?.appointmentTime || null);
        setValue("purchaseMaterialCost", details?.purchaseMaterialCost || null);
        setValue("transportCharges", details?.transportCharges || null);
        setValue("otherCharges", details?.otherCharges || null);
        setValue("transportKM", details?.transportKM || null);
        setValue("transportAmount", details?.transportAmount || null);
        setValue("serviceCharge", details?.serviceCharge || null);
        setValue("otherDetails", details?.otherDetails || null);
        setValue("totalTechnicianShare", details?.totalTechnicianShare || null);
        setValue("amcCharge", details?.amcCharge || null);
        setValue("amcDate", details?.amcDate || null);
        setValue("amcTime", details?.amcTime || null);
        setValue("amcYear", details?.amcYear || null);
        setValue("amc", details?.amc || null);
        setValue("warrantyDetails", details?.warrantyDetails || null);
        setValue("productcapacity", details?.productcapacity || null);
        setIsTechnicianDataSet(true);
      }
    }
  }, [mobileNo, data, setValue, alternateNo, isTechnicianDataSet]);

  const handleClearDataChange = (event) => {
    setClrData(event.target.checked);
    if (event.target.checked) {
      // Reset all fields if clearData is checked
      setValue("product", "");
      setValue("modelName", "");
      setValue("brandName", "");
      setValue("serialNo", "");
      setValue("issue", "");
      setBase64Image({
        productImg: "",
        modelImg: "",
        serialImg: "",
      });
      setValue("selectSlot", "");
      setValue("amcDate", "");
      setValue("endDate", "");
      setValue("amcTime", "");
      setValue("amcYear", "");
      setValue("amc", "");
      setValue("warrantyDetails", "");
      setValue("amcCharge", "");
      setValue("replaceMaterialName", "");
      setValue("replaceMaterialCharge", "");
      setValue("purchaseMaterialCost", "");
      setValue("transportCharges", "");
      setValue("otherCharges", "");
      setValue("transportKM", "");
      setValue("transportAmount", "");
      setValue("serviceCharge", "");
      setValue("otherDetails", "");
      setValue("totalAmt", "");
      setValue("serviceName", "");
      setValue("productcapacity", "");
      setValue("productCharge", "");
    } else {
      // Restore fetched data when checkbox is unchecked
      if (fetchedData) {
        setValue("product", fetchedData?.product);
        setValue("modelName", fetchedData?.product);
        setValue("brandName", fetchedData?.product);
        setValue("serialNo", fetchedData?.product);
        setValue("issue", fetchedData?.product);
        setBase64Image({
          productImg: fetchedData?.productImage,
          modelImg: fetchedData?.modelImage,
          serialImg: fetchedData?.modelImage,
        });

        setValue("amcDate", fetchedData?.amcDate);
        setValue("endDate", fetchedData?.endDate);
        setValue("amcTime", fetchedData?.amcTime);
        setValue("amcYear", fetchedData?.amcYear);
        setValue("amc", fetchedData?.amc);
        setValue("warrantyDetails", fetchedData?.warrantyDetails);
        setValue("amcCharge", fetchedData?.amcCharge);
        setValue("replaceMaterialName", fetchedData?.replaceMaterialName);
        setValue("replaceMaterialCharge", fetchedData?.replaceMaterialCharge);
        setValue("purchaseMaterialCost", fetchedData?.purchaseMaterialCost);
        setValue("transportCharges", fetchedData?.transportCharges);
        setValue("otherCharges", fetchedData?.otherCharges);
        setValue("transportKM", fetchedData?.transportKM);
        setValue("transportAmount", fetchedData?.transportAmount);
        setValue("serviceCharge", fetchedData?.serviceCharge);
        setValue("otherDetails", fetchedData?.otherDetails);
        setValue("totalAmt", fetchedData?.totalAmount);
        setValue("serviceName", fetchedData?.serviceName);
        setValue("productcapacity", fetchedData?.productcapacity);
        setValue("productCharge", fetchedData?.productCharge);
      }
    }
  };

  const Submit = (data) => {
    const formValue = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      address: data?.address,
      mobileNo: data?.mobileNo,
      alternateNo: data?.alternateNo,
      modelName: data?.modelName,
      product: data?.product,
      issue: data?.issue,
      photo: data?.photo || null,
      serviceName: data?.serviceName,
      selectSlot: data?.selectSlot,
      netAmount: data?.netAmt,
      technicianShare: data?.technicianShare,
      ownerShare: data?.ownerShare,
      place: data?.place,
      pincode: data?.pincode,
      referenceName: data?.referenceName,
      referencePhoneNumber: data?.referencePhoneNumber,
      date: data && data?.date ? moment(data?.date).format("YYYY-MM-DD") : null,
      appointmentDate:
        data && data?.appointmentDate
          ? moment(data?.appointmentDate).format("YYYY-MM-DD")
          : null,
      appointmentTime: data?.appointmentTime,
      transportCharges: data?.transportCharges,
      otherCharges: data?.otherCharges,
      purchaseMaterialCost: data?.purchaseMaterialCost,
      transportKM: data?.transportKM,
      transportAmount: data?.transportAmount,
      brandName: data?.brandName,
      serialNo: data?.serialNo,
      replaceMaterialName: data?.replaceMaterialName,
      replaceMaterialCharge: data?.replaceMaterialCharge,
      serviceCharge: data?.serviceCharge,
      productImage: base64Image?.productImg,
      modelImage: base64Image?.modelImg,
      serialImage: base64Image?.serialImg,
      otherDetails: data?.otherDetails,
      totalTechnicianShare: data?.totalTechnicianShare,
      amcCharge: data && data?.amcCharge,
      amcDate: moment(data && data?.amcDate).format("YYYY-MM-DD") || null,
      endDate:
        data && data?.endDate
          ? moment(data && data?.endDate).format("YYYY-MM-DD")
          : null,
      amcTime: (data && data?.amcTime) || null,
      amcYear: (data && data?.amcYear) || null,
      amc: (data && data?.amc) || null,
      warrantyDetails: (data && data?.warrantyDetails) || null,
      clearData: data?.clearData,
      clearPayment: data?.clearPayment,
      modelType: data?.modelType,
      productCharge: data?.productCharge,
      totalAmount: data?.totalAmt || null,
      balanceAmount: data?.balanceAmount || null,
      currentAmount: data?.currentAmount || null,
      paymentStatus: data?.paymentStatus || null,
      // actualAmount: data?.actualAmount,
      productcapacity: data?.productcapacity || null,
    };
    setDeleteConfirmAlert(formValue);
    setFormData(formValue);
  };

  return (
    <div className="w-100 p-3 px-4 ">
      <div
        className="row bg-white px-3 pb-4 "
        style={{
          height: "96vh",
          borderRadius: "10px",
          overflowY: "scroll",
          scrollbarWidth: "thin",
          position: "relative",
          width: "100%",
        }}
      >
        <div className="registe_date_picker_block">
          <Controller
            name="registerDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                selected={field.value}
                onChange={(date) => field.onChange(date)}
                dateFormat="yyyy-MM-dd"
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={50}
                className="register_date_picker" // Style input field
              />
            )}
          />
        </div>

        <header
          className="fs-2 fw-bold py-1 ps-4  text-center"
          style={{
            height: "10%",
            position: "sticky",
            top: "0px",
            backgroundColor: "#fff",
          }}
        >
          {state && state ? <>EDIT REQUEST </> : <>ADD REQUEST</>}
        </header>

        <section>
          <form onSubmit={handleSubmit(Submit)}>
            <div style={{}}>
              <div className="addUser_ticket_label py-3">
                <h4 className="fw-bold addrequest-heading">
                  {" "}
                  Customer Details
                </h4>
                <div className=" col-12 mb-3 d-flex ">
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      First Name
                    </label>
                    <Controller
                      control={control}
                      name="firstName"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                    />
                  </div>
                  <div className="col-6">
                    <label className="col-5 addUser_ticket_label">
                      Last Name
                    </label>
                    <Controller
                      control={control}
                      name="lastName"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-12 mb-3 ">
                  <div className="d-flex">
                    <label
                      style={{ width: "20%" }}
                      className="addUser_ticket_label"
                    >
                      Address
                    </label>
                    <Controller
                      control={control}
                      name="address"
                      render={(field) => (
                        <InputField
                          addUserInputCss=" col-9 --addUser_companyName_inputField"
                          {...field}
                          type="text"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className=" col-12 mb-3 d-flex">
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">Place</label>
                    <Controller
                      control={control}
                      name="place"
                      render={(field) => (
                        <InputField
                          addUserInputCss=" --addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                    />
                  </div>
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      Pincode
                    </label>
                    <Controller
                      control={control}
                      name="pincode"
                      render={(field) => (
                        <InputField
                          addUserInputCss=" --addUser_inputFields"
                          {...field}
                          type="number"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className=" col-12 mb-3 d-flex">
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      Mobile No
                    </label>

                    <Controller
                      control={control}
                      name="mobileNo"
                      render={(field) => (
                        <InputField
                          addUserInputCss=" --addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                    />
                  </div>
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      Alternate No
                    </label>
                    <Controller
                      control={control}
                      name="alternateNo"
                      render={(field) => (
                        <InputField
                          // style = {{margin}}
                          addUserInputCss=" --addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className=" col-12 mb-3 d-flex">
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      Reference Person
                    </label>
                    <Controller
                      control={control}
                      name="referenceName"
                      render={(field) => (
                        <InputField
                          // style = {{margin}}
                          addUserInputCss=" --addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                    />
                  </div>
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      Reference Number
                    </label>
                    <Controller
                      control={control}
                      name="referencePhoneNumber"
                      render={(field) => (
                        <InputField
                          // style = {{margin}}
                          addUserInputCss=" --addUser_inputFields"
                          {...field}
                          type="tele"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>

                <div className=" col-12 mb-3 d-flex">
                  <div className="col-6">
                    <label className="col-5 addUser_ticket_label">
                      Appointment Date
                    </label>

                    <Controller
                      name="appointmentDate"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          dateFormat="yyyy/MM/dd"
                          minDate={new Date()}
                          className="--addUser_inputFields_date"
                        />
                      )}
                    />
                  </div>
                  <div className="col-6">
                    <label className="col-5 addUser_ticket_label">Time</label>

                    <Controller
                      control={control}
                      name="appointmentTime"
                      defaultValue={currentTime} // Use current time as default
                      render={({ field }) => (
                        <input
                          {...field} // Spread the field props to InputField
                          type="time" // Set type to "time"
                          className="--addUser_inputFields" // Optional CSS class for custom styling
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              {/* Product Details */}
              <div className="py-3">
                <h4 className="fw-bold addrequest-heading"> Product Details</h4>
                <div className="mt-3 mb-2 px-1">
                  {isTechnicianDataSet && !state && (
                    <label className="ckbox-label">
                      <Controller
                        name="clearData"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="checkbox"
                            checked={clrData}
                            {...field}
                            onChange={handleClearDataChange}
                            style={{
                              width: "18px",
                              height: "18px",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      />
                      Clear Below Data
                    </label>
                  )}
                </div>

                {state && state?.requestID ? (
                  <>
                    <div className=" col-12 mb-3 d-flex">
                      <div className="col-6 d-flex align-items-center">
                        <label className="col-5 addUser_ticket_label">
                          Product
                        </label>

                        <Controller
                          control={control}
                          name="product"
                          render={(field) => (
                            <InputField
                              // style = {{margin}}
                              addUserInputCss="--addUser_inputFields"
                              {...field}
                              type="text"
                            />
                          )}
                        />
                      </div>
                      <div className="col-6 d-flex align-items-center">
                        <label className="col-5 addUser_ticket_label">
                          Product Type
                        </label>
                        <Controller
                          control={control}
                          name="modelType"
                          render={(field) => (
                            <InputField
                              // style = {{margin}}
                              addUserInputCss="--addUser_inputFields"
                              {...field}
                              type="text"
                            />
                          )}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className=" col-12 mb-3 d-flex">
                      <div className="col-6 d-flex align-items-center">
                        <label className="col-5 addUser_ticket_label">
                          Product
                        </label>

                        <Controller
                          name="product"
                          control={control}
                          render={({ field }) => (
                            <select
                              {...field}
                              onChange={(e) => {
                                const selectedValue = e.target.value;
                                field.onChange(selectedValue);
                                handleProductChange(selectedValue); // Update model and issue options when product changes
                              }}
                              className="--addUser_inputFields"
                            >
                              <option value="">Select Product</option>
                              {productData && productData?.length > 0 ? (
                                productData?.map((product) => (
                                  <option key={product} value={product}>
                                    {product}
                                  </option>
                                ))
                              ) : (
                                <option value="" disabled>
                                  Loading...
                                </option>
                              )}
                            </select>
                          )}
                        />
                      </div>
                      <div className="col-6 d-flex align-items-center">
                        <label className="col-5 addUser_ticket_label">
                          Product Type
                        </label>

                        <Controller
                          name="modelType"
                          control={control}
                          render={({ field }) => (
                            <CreatableSelect
                              {...field}
                              options={modelOptions}
                              className="--addUser_inputFields"
                              value={
                                modelOptions?.find(
                                  (option) => option.value === field.value
                                ) || null
                              }
                              onChange={(selectedOption) => {
                                if (selectedOption) {
                                  field.onChange(selectedOption.value);
                                } else {
                                  field.onChange("");
                                }
                              }}
                              isClearable
                              formatCreateLabel={(inputValue) =>
                                `Create "${inputValue}"`
                              }
                              placeholder="Select Model"
                            />
                          )}
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className=" col-12 mb-3 d-flex ">
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      Brand Name
                    </label>

                    <Controller
                      name="brandName"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="text"
                          className="--addUser_inputFields"
                          placeholder="Brand Name"
                        />
                      )}
                    />
                  </div>
                  <div className="col-6">
                    <label className="col-5 addUser_ticket_label px-1">
                      Model Name
                    </label>
                    <Controller
                      control={control}
                      name="modelName"
                      render={(field) => (
                        <InputField
                          // style = {{margin}}
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className=" col-12 mb-3 d-flex ">
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      Product Capacity
                    </label>
                    <Controller
                      control={control}
                      name="productcapacity"
                      render={({ field }) => (
                        <input
                          className="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      Serial No
                    </label>
                    <Controller
                      control={control}
                      name="serialNo"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-12 mb-3 ">
                  <div className="d-flex">
                    <label
                      style={{ width: "20%" }}
                      className="addUser_ticket_label"
                    >
                      Issue
                    </label>

                    {state && state?.requestID ? (
                      <>
                        <Controller
                          control={control}
                          name="issue"
                          render={({ field }) => (
                            <input
                              // style = {{margin}}
                              className="col-9 --addUser_companyName_inputField"
                              {...field}
                              type="text"
                            />
                          )}
                        />
                      </>
                    ) : (
                      <>
                        <Controller
                          name="issue"
                          control={control}
                          render={({ field }) => (
                            <CreatableSelect
                              {...field}
                              options={issueOptions}
                              className="col-9 --addUser_companyName_inputField"
                              value={
                                issueOptions?.find(
                                  (option) => option.value === field.value
                                ) || null
                              }
                              onChange={(selectedOption) => {
                                if (selectedOption) {
                                  field.onChange(selectedOption.value);
                                } else {
                                  field.onChange("");
                                }
                              }}
                              isClearable
                              formatCreateLabel={(inputValue) =>
                                `Create "${inputValue}"`
                              }
                              placeholder={
                                issueOptions?.length > 0
                                  ? "Select Issue"
                                  : "No Issues Available"
                              }
                            />
                          )}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="d-flex">
                    <label
                      style={{ width: "20%" }}
                      className="addUser_ticket_label"
                    >
                      Product Image
                    </label>

                    <label
                      for="photoImg"
                      className="col-3 ms-2 aadharFileInput text-center d-flex justify-content-center align-items-center"
                      style={{ width: "50%" }}
                    >
                      UPLOAD A FILE HERE{" "}
                      <img className="h-75" src={addIcon} alt="" />
                    </label>
                    <img
                      style={{ height: "30px", marginLeft: "10px" }}
                      alt=""
                      src={`data:image/jpeg;base64,${base64Image?.productImg}`}
                    />
                    {base64Image?.productImg && (
                      <img
                        onClick={() => {
                          // setBase64Image("");
                          removeKey("productImg");
                        }}
                        className=" ps-2 h-75"
                        src={wrong}
                        alt=""
                      />
                    )}
                    <Controller
                      control={control}
                      name="productImage"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <input
                          value={value}
                          className=" ms-2 "
                          type="file"
                          name="uploadfile"
                          id="photoImg"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            onChange(handleImageInputChange(e, "productImg"))
                          }
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="d-flex">
                    <label
                      style={{ width: "20%" }}
                      className="addUser_ticket_label"
                    >
                      Model Image
                    </label>

                    <label
                      for="modelImage"
                      className="col-3 ms-2 aadharFileInput text-center d-flex justify-content-center align-items-center"
                      style={{ width: "50%" }}
                    >
                      UPLOAD A FILE HERE{" "}
                      <img className="hZ-75" src={addIcon} alt="" />
                    </label>
                    <img
                      style={{ height: "30px", marginLeft: "10px" }}
                      alt=""
                      src={`data:image/jpeg;base64,${base64Image?.modelImg}`}
                    />
                    {base64Image?.modelImg && (
                      <img
                        onClick={() => {
                          // setBase64Image("");
                          removeKey("modelImg");
                        }}
                        className=" ps-2 h-75"
                        src={wrong}
                        alt=""
                      />
                    )}
                    <Controller
                      control={control}
                      name="modelImage"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <input
                          value={value}
                          className=" ms-2 "
                          type="file"
                          name="uploadfile"
                          id="modelImage"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            onChange(handleImageInputChange(e, "modelImg"))
                          }
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-4">
                  <div className="d-flex">
                    <label
                      style={{ width: "20%" }}
                      className="addUser_ticket_label"
                    >
                      Serial Image
                    </label>

                    <label
                      for="serialImg"
                      className="col-3 ms-2 aadharFileInput text-center d-flex justify-content-center align-items-center"
                      style={{ width: "50%" }}
                    >
                      UPLOAD A FILE HERE{" "}
                      <img className="h-75" src={addIcon} alt="" />
                    </label>
                    <img
                      style={{ height: "30px", marginLeft: "10px" }}
                      alt=""
                      src={`data:image/jpeg;base64,${base64Image?.serialImg}`}
                    />
                    {base64Image?.serialImg && (
                      <img
                        onClick={() => {
                          // setBase64Image("");
                          removeKey("serialImg");
                        }}
                        className=" ps-2 h-75"
                        src={wrong}
                        alt=""
                      />
                    )}
                    <Controller
                      control={control}
                      name="serialImage"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <input
                          value={value}
                          className=" ms-2 "
                          type="file"
                          name="uploadfile"
                          id="serialImg"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            onChange(handleImageInputChange(e, "serialImg"))
                          }
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 px-1 my-1">
                  <div>
                    <label className="d-flex align-items-center gap-2">
                      <Controller
                        name="amc"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <input
                            type="checkbox"
                            {...field}
                            style={{ width: "18px", height: "18px" }}
                          />
                        )}
                      />
                      Annual Maintanence Charge
                    </label>
                  </div>
                </div>
                {isChecked && (
                  <>
                    <div className="col-12 my-3 d-flex">
                      <div className="col-6 ">
                        <label className="col-5 addUser_ticket_label">
                          AMC Date
                        </label>
                        <Controller
                          name="amcDate"
                          control={control}
                          defaultValue={new Date()}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              className="--addUser_inputFields_date"
                              selected={
                                field.value
                                  ? moment(field.value).toDate()
                                  : null
                              }
                              onChange={(date) =>
                                field.onChange(moment(date).toDate())
                              }
                              dateFormat="yyyy/MM/dd"
                              minDate={new Date()}
                            />
                          )}
                        />
                      </div>
                      <div className="col-6 ">
                        <label className="col-5 addUser_ticket_label">
                          AMC Time
                        </label>
                        <Controller
                          control={control}
                          name="amcTime"
                          defaultValue={currentTime} // Use current time as default
                          render={({ field }) => (
                            <input
                              {...field} // Spread the field props to InputField
                              type="time" // Set type to "time"
                              className="--addUser_inputFields" // Optional CSS class for custom styling
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-12 my-3 d-flex">
                      <div className="col-6 d-flex align-items-center">
                        <label className="col-5 addUser_ticket_label">
                          AMC T&C
                        </label>

                        <Controller
                          name="warrantyDetails"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <CreatableSelect
                              {...field}
                              options={options}
                              className="--addUser_inputFields_dropdown" // Add custom class here
                              value={options.find(
                                (option) => option.value === field.value
                              )}
                              onChange={(selectedOption) => {
                                if (selectedOption) {
                                  field.onChange(selectedOption.value); // Set selected value
                                } else {
                                  field.onChange(""); // Clear value when user types a custom value
                                }
                              }}
                              isClearable // Allow clearing the selection
                              formatCreateLabel={(inputValue) => inputValue} // Remove "Create" text
                              placeholder=""
                            />
                          )}
                        />
                      </div>
                      <div className="col-6">
                        <label className="col-5 addUser_ticket_label">
                          Charge
                        </label>
                        <Controller
                          control={control}
                          name="productCharge"
                          render={({ field }) => (
                            <input
                              {...field}
                              className="--addUser_inputFields"
                              type="number"
                            />
                          )}
                          // rules={{ required: true }}
                        />
                      </div>
                    </div>
                    <div className="col-12 my-2 d-flex">
                      <div className="col-6">
                        <label className="col-5 addUser_ticket_label">
                          AMC Duration
                        </label>
                        <Controller
                          name="amcYear"
                          control={control}
                          render={({ field }) => (
                            <select
                              {...field}
                              className="--addUser_inputFields"
                            >
                              <option value="">Select Year</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          )}
                        />
                      </div>
                      <div className="col-6">
                        <label className="col-5 addUser_ticket_label">
                          AMC Charge
                        </label>
                        <Controller
                          control={control}
                          name="amcCharge"
                          render={(field) => (
                            <InputField
                              {...field}
                              addUserInputCss=" --addUser_inputFields"
                              type="number"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-12 my-2 d-flex">
                      <div className="col-6">
                        <label className="col-5 addUser_ticket_label">
                          AMC End Date
                        </label>
                        <Controller
                          name="endDate"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              className="--addUser_inputFields_date"
                              selected={
                                field.value
                                  ? moment(field.value).toDate()
                                  : null
                              }
                              onChange={(date) =>
                                field.onChange(moment(date).toDate())
                              }
                              dateFormat="yyyy/MM/dd"
                              minDate={new Date()}
                              // disabled={true}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              {state && state ? (
                <>
                  <div className="col-12 py-3">
                    <h4 className="fw-bold addrequest-heading">
                      {" "}
                      Payment and Time Details
                    </h4>
                    {/* Product Details */}
                    <div className="col-12 mb-3 d-flex justify-content-between">
                      <label
                        style={{ width: "20%" }}
                        className=" addUser_ticket_label"
                      >
                        Product Name
                      </label>
                      <div className="col-6 me-5 d-flex justify-content-end">
                        <Controller
                          control={control}
                          name="serviceName"
                          render={(field) => (
                            <InputField
                              addUserInputCss="--addUser_inputFields"
                              {...field}
                              type="text"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-12 mb-3 d-flex justify-content-between">
                      <label
                        style={{ width: "20%" }}
                        className=" addUser_ticket_label"
                      >
                        Select Time
                      </label>
                      <div className="col-6 me-5 d-flex justify-content-end">
                        <Controller
                          control={control}
                          name="selectSlot"
                          defaultValue={currentTime} // Use current time as default
                          render={({ field }) => (
                            <input
                              {...field} // Spread the field props to InputField
                              type="time" // Set type to "time"
                              className="--addUser_inputFields" // Optional CSS class for custom styling
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-12 mb-3 d-flex justify-content-between">
                      <label
                        style={{ width: "20%" }}
                        className=" addUser_ticket_label"
                      >
                        Payment Date
                      </label>
                      <div className="col-6 me-5 d-flex justify-content-end">
                        <Controller
                          name="date"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              selected={field.value}
                              onChange={(date) => field.onChange(date)}
                              dateFormat="yyyy/MM/dd"
                              // minDate={new Date()}
                              className="--addUser_inputFields_date"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-12 mb-3 d-flex justify-content-between">
                      <label
                        style={{ width: "25%" }}
                        className=" addUser_ticket_label"
                      >
                        Replace Material Name
                      </label>
                      <div className="col-6 me-5 d-flex justify-content-end">
                        <Controller
                          control={control}
                          name="replaceMaterialName"
                          render={({ field }) => (
                            <input
                              className="--addUser_inputFields"
                              {...field}
                              type="text"
                            />
                          )}
                          // rules={{ required: true }}
                        />
                      </div>
                    </div>
                    <div className="col-12 mb-3 d-flex justify-content-between">
                      <label
                        style={{ width: "25%" }}
                        className=" addUser_ticket_label"
                      >
                        Purchase Material Cost
                      </label>
                      <div className="truefalseinput_block">
                        <div className="truefalseinput_sub_block">
                          <input
                            type="checkbox"
                            value="MaterialYes"
                            id="MaterialYes"
                            checked={serviceselected === "MaterialYes"}
                            onChange={() =>
                              handleCheckboxChanges("MaterialYes")
                            }
                            style={{ width: "20px", height: "20px" }}
                          />
                          <label htmlFor="MaterialYes">Yes</label>
                        </div>
                        <div className="truefalseinput_sub_block">
                          <input
                            type="checkbox"
                            value="MaterialNo"
                            id="MaterialNo"
                            checked={serviceselected === "MaterialNo"}
                            onChange={() => handleCheckboxChanges("MaterialNo")}
                            style={{ width: "20px", height: "20px" }}
                          />
                          <label htmlFor="MaterialNo">No</label>
                        </div>
                      </div>
                      <div className="col-6 me-5 d-flex justify-content-end">
                        <Controller
                          control={control}
                          name="purchaseMaterialCost"
                          render={({ field }) => (
                            <input
                              className="--addUser_inputFields"
                              {...field}
                              type="number"
                              step="any"
                            />
                          )}
                          // rules={{ required: true }}
                        />
                      </div>
                    </div>
                    <div className="col-12 mb-3 d-flex justify-content-between">
                      <label
                        style={{ width: "25%" }}
                        className=" addUser_ticket_label"
                      >
                        Replace Material Charge
                      </label>
                      <div className="col-6 me-5 d-flex justify-content-end">
                        <Controller
                          control={control}
                          name="replaceMaterialCharge"
                          render={({ field }) => (
                            <input
                              className="--addUser_inputFields"
                              {...field}
                              type="number"
                              step="any"
                            />
                          )}
                          // rules={{ required: true }}
                        />
                      </div>
                    </div>
                    <div className="col-12 mb-3 d-flex justify-content-between">
                      <label
                        style={{ width: "20%" }}
                        className=" addUser_ticket_label"
                      >
                        Service Charge
                      </label>
                      <div className="truefalseinput_block">
                        <div className="truefalseinput_sub_block">
                          <input
                            type="checkbox"
                            value="Yes"
                            id="yes"
                            checked={selected === "Yes"}
                            onChange={() => handleCheckboxChange("Yes")}
                            style={{ width: "20px", height: "20px" }}
                          />
                          <label htmlFor="yes">Yes</label>
                        </div>
                        <div className="truefalseinput_sub_block">
                          <input
                            type="checkbox"
                            value="No"
                            id="no"
                            checked={selected === "No"}
                            onChange={() => handleCheckboxChange("No")}
                            style={{ width: "20px", height: "20px" }}
                          />
                          <label htmlFor="no">No</label>
                        </div>
                      </div>

                      <div className="col-6 me-5 d-flex justify-content-end">
                        <Controller
                          control={control}
                          name="serviceCharge"
                          render={({ field }) => (
                            <input
                              className="--addUser_inputFields"
                              {...field}
                              type="number"
                            />
                          )}
                          // rules={{ required: true }}
                        />
                      </div>
                    </div>
                    <div className="col-12 mb-3 d-flex justify-content-between">
                      <label
                        style={{ width: "20%" }}
                        className=" addUser_ticket_label"
                      >
                        Total Amount
                      </label>
                      <div className="col-6 me-5 d-flex justify-content-end">
                        <Controller
                          control={control}
                          name="totalAmt"
                          render={({ field }) => (
                            <input
                              className="--addUser_inputFields"
                              {...field}
                              type="number"
                              step="any"
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div className="col-12 mb-3 d-flex justify-content-between">
                      <label
                        style={{ width: "20%" }}
                        className=" addUser_ticket_label"
                      >
                        Technician Share
                      </label>
                      <div className="col-6 me-5 d-flex justify-content-end">
                        <Controller
                          control={control}
                          name="technicianShare"
                          render={({ field }) => (
                            <input
                              className="--addUser_inputFields"
                              {...field}
                              type="number"
                              step="any"
                              readOnly
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-12 mb-3 d-flex justify-content-between">
                      <label
                        style={{ width: "20%" }}
                        className=" addUser_ticket_label"
                      >
                        Owner Share
                      </label>
                      <div className="col-6 me-5 d-flex justify-content-end">
                        <Controller
                          control={control}
                          name="ownerShare"
                          render={({ field }) => (
                            <input
                              className="--addUser_inputFields"
                              {...field}
                              type="number"
                              step="any"
                              readOnly
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-12 mb-3 d-flex justify-content-between align-items">
                      <label
                        style={{ width: "35%" }}
                        className=" addUser_ticket_label"
                      >
                        Transport Charge
                      </label>
                      <Controller
                        control={control}
                        name="transportKM"
                        render={(field) => (
                          <InputField
                            transportInput="transportInput"
                            {...field}
                            type="number"
                            placeholder="K/M"
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="transportAmount"
                        render={(field) => (
                          <InputField
                            {...field}
                            type="number"
                            transportInput="transportInput"
                            placeholder="Amt Per K/M"
                          />
                        )}
                      />

                      <div className="col-6 me-5 d-flex justify-content-end">
                        <Controller
                          control={control}
                          name="transportCharges"
                          render={(field) => (
                            <InputField
                              addUserInputCss="--addUser_inputFields"
                              {...field}
                              type="number"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-12 mb-3 d-flex justify-content-between align-items-center">
                      <label
                        style={{ width: "20%" }}
                        className=" addUser_ticket_label"
                      >
                        Other Charge
                      </label>
                      <Controller
                        control={control}
                        name="otherDetails"
                        render={(field) => (
                          <InputField
                            transportInput="transportInput"
                            {...field}
                            type="text"
                            placeholder="Others Details"
                          />
                        )}
                      />
                      <div className="col-6 me-5 d-flex justify-content-end">
                        <Controller
                          control={control}
                          name="otherCharges"
                          render={(field) => (
                            <InputField
                              addUserInputCss="--addUser_inputFields"
                              {...field}
                              type="number"
                            />
                          )}
                          // rules={{ required: true }}
                        />
                      </div>
                    </div>
                    <div className="col-12 mb-3 d-flex justify-content-between">
                      <label
                        style={{ width: "30%" }}
                        className=" addUser_ticket_label"
                      >
                        Total Technician Share
                      </label>
                      <div className="col-6 me-5 d-flex justify-content-end">
                        <Controller
                          control={control}
                          name="totalTechnicianShare"
                          render={({ field }) => (
                            <input
                              {...field}
                              type="number"
                              className="--addUser_inputFields"
                              readOnly
                            />
                          )}
                        />
                      </div>
                    </div>

                    {state && state?.requestID && (
                      <>
                        <div className="col-12 mb-3 d-flex justify-content-between">
                          <label
                            style={{ width: "30%" }}
                            className=" addUser_ticket_label"
                          >
                            Amount To Pay
                          </label>
                          <div className="col-6 me-5 d-flex justify-content-end">
                            <Controller
                              control={control}
                              name="actualAmount"
                              defaultValue={0}
                              render={({ field }) => (
                                <input
                                  className="--addUser_inputFields"
                                  {...field}
                                  type="number"
                                  step="any"
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-12 mb-3 d-flex justify-content-between">
                          <label
                            style={{ width: "30%" }}
                            className=" addUser_ticket_label"
                          >
                            Amount Paid
                          </label>
                          <div className="col-6 me-5 d-flex justify-content-end">
                            <Controller
                              control={control}
                              name="currentAmount"
                              defaultValue={0}
                              render={({ field }) => (
                                <input
                                  className="--addUser_inputFields"
                                  {...field}
                                  type="number"
                                  step="any"
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-12 mb-3 d-flex justify-content-between">
                          <label
                            style={{ width: "30%" }}
                            className=" addUser_ticket_label"
                          >
                            Balance Amount
                          </label>
                          <div className="col-6 me-5 d-flex justify-content-end">
                            <Controller
                              control={control}
                              name="balanceAmount"
                              defaultValue={0}
                              render={({ field }) => (
                                <input
                                  className="--addUser_inputFields"
                                  {...field}
                                  type="number"
                                  step="any"
                                  // style={{ display: "none" }}
                                />
                              )}
                            />
                          </div>
                        </div>

                        <div className="col-12 mb-3 d-flex justify-content-between">
                          <label
                            style={{ width: "30%" }}
                            className=" addUser_ticket_label"
                          >
                            Payment Status
                          </label>
                          <div className="col-6 me-5 d-flex justify-content-end">
                            <Controller
                              name="paymentStatus"
                              control={control}
                              defaultValue="Pending"
                              render={({ field }) => (
                                <select
                                  {...field}
                                  className="--addUser_inputFields"
                                >
                                  <option value=""></option>
                                  <option value="Pending">Pending</option>
                                  <option value="Completed">Completed</option>
                                </select>
                              )}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className="col-12 addUser_submitBtn_field ">
                <button
                  // onClick={() => {
                  //   setDeleteConfirmAlert("add");
                  // }}
                  className=" col-5 addUser_submitBtn"
                  type="submit"
                >
                  {/* {" "}
                  SUBMIT */}
                  {isLoading ? (
                    <div className="spinner-border text-light" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "SUBMIT"
                  )}
                </button>
              </div>
            </div>
          </form>
        </section>
        <AlertModal
          deleteConfirmAlert={deleteConfirmAlert}
          setDeleteConfirmAlert={setDeleteConfirmAlert}
          setDeleteSuccessfully={setDeleteSuccessfully}
          deleteSuccessfully={deleteSuccessfully}
          isLoading={isLoading}
          formData={formData}
          yesOnClick={() => {
            // HandlerApi(deleteConfirmAlert);
            // mutate(deleteConfirmAlert)
            if (state && state?.requestID) {
              console.log("state?.requestID", deleteConfirmAlert);
              UpdateFormValue.mutate(deleteConfirmAlert);
            } else {
              mutate(deleteConfirmAlert);
            }
          }}
          state={state}
        />
      </div>
    </div>
  );
}
