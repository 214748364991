import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginScreen from "../../screen/loginscreen/LoginScreen";
import OverviewScreen from "../../screen/overview/OverviewScreen";
import { QueryClient, QueryClientProvider } from "react-query";
import SideBar from "../../component/SideBar";
import NewTechnician from "../../screen/NewTechnician";
import ViewTechnicians from "../../screen/techniciansscreen/ViewTechnicians";
import ViewUserManagement from "../../screen/usermanagementscreen/ViewUserManagement";
import AddRequest from "../../screen/AddRequest";
import NewUser from "../../screen/NewUser";
import AddProduct from "../../screen/AddProduct";
import InvoicePage from "../../screen/InvoicePage";
import ViewProduct from "../../screen/viewproductscreen/ViewProduct";

const Navigation = () => {
  const queryClient = new QueryClient();
  const loginTrue = sessionStorage.getItem("login");
  const register = sessionStorage.getItem("userData");
  let details = JSON.parse(register);
  let role = details?.role;

  // const loginValue = true;
  return (
    <>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          {loginTrue ? (
            <div
              className="d-flex"
              style={{ backgroundColor: "#4068F3", height: "auto" }}
            >
              {role == "superAdmin" ? (
                <>
                  <SideBar />
                  <Routes>
                    <Route path="/" element={<OverviewScreen />} />
                    <Route path="/newTechnician" element={<NewTechnician />} />
                    <Route path="/addRequest" element={<AddRequest />} />
                    <Route path="/newUser" element={<NewUser />} />
                    <Route
                      path="/viewTechnicians"
                      element={<ViewTechnicians />}
                    />
                    <Route path="/addProduct" element={<AddProduct />} />
                    <Route path="/viewProduct" element={<ViewProduct />} />
                    <Route
                      path="/viewUserManagement"
                      element={<ViewUserManagement />}
                    />
                    <Route path="/invoice" element={<InvoicePage />} />
                  </Routes>
                </>
              ) : (
                <>
                  <SideBar />
                  <Routes>
                    <Route path="/" element={<OverviewScreen />} />
                    <Route path="/newTechnician" element={<NewTechnician />} />
                    <Route path="/addRequest" element={<AddRequest />} />
                    <Route path="/newUser" element={<NewUser />} />
                    <Route
                      path="/viewTechnicians"
                      element={<ViewTechnicians />}
                    />
                    <Route
                      path="/viewUserManagement"
                      element={<ViewUserManagement />}
                    />
                    <Route path="/addProduct" element={<AddProduct />} />
                    <Route path="/viewProduct" element={<ViewProduct />} />
                    <Route path="/invoice" element={<InvoicePage />} />
                  </Routes>
                </>
              )}
            </div>
          ) : (
            <div>
              <Routes>
                <Route path="/" element={<LoginScreen />} />
              </Routes>
            </div>
          )}
        </QueryClientProvider>
      </BrowserRouter>
    </>
  );
};

export default Navigation;
