import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { useNavigate } from "react-router";
import sandtimer from "../../assets/images/sandtimer.png";
import wrong from "../../../common/assets/images/wrong.svg";
import PopupBox from "../../../component/PopupBox";

export default function AlertModal(props) {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  // console.log("state", props?.state);
  const closeModal = () => {
    setIsOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    // boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const handleClose = () => {
    props?.setOpen(false);
  };

 
  return (
    <div>
      <Modal
        open={props.open || props.UpdateOpen}
        onClose={props.handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          className="d-flex flex-column justify-content-between align-items-center"
          sx={{
            ...style,
            width: 300,
            height: 200,
            borderRadius: 6,
            backgroundColor: "#CCDDF9",
          }}
        >
          <p
            className="text-center pt-2 fs-5 mb-0 fw-bold"
            style={{ color: "#004BCB" }}
          >
            {props.UpdateOpen ? props.UpdateOpen : props.open}
          </p>
          <p
            className="mb-0 text-center fs-6 fw-bold "
            style={{ color: "#004BCB" }}
          >
            {" "}
            WAIT FOR SOME TIMES
          </p>
          <img className="pt-2" src={sandtimer} alt="" />
          <button
            className="modalSubmitBtn p-1 fw-bold "
            onClick={() => {
              props.UpdateOpen
                ? props.setUpdateOpen(false)
                : props.setOpen(false);
              //   props.handleClose()
              navigate(props?.navigate);
              // console.log("props.navigate", props.navigate);

              //   if (props.customerLoginValue) {
              //     navigate("/UserRegisterTable");
              //   }  if (props.customerLoginValue === null){
              //     navigate("/");
              //   }
            }}
          >
            Done
          </button>
        </Box>
      </Modal>
      {/* {console.log('props.deleteConfirmAlert',props.deleteConfirmAlert)} */}
      <Modal
        open={props.deleteConfirmAlert}
        // open={true}
        onClose={props.handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          className="d-flex flex-column justify-content-between align-items-center"
          sx={{
            // ...style,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 550,
            height: 180,
            borderRadius: 6,
            backgroundColor: "#004BCB",
          }}
        >
          <div>
            <div
              className="d-flex pt-2 justify-content-end align-items-end"
              onClick={() => {
                props.setDeleteConfirmAlert(false);
              }}
              style={{ position: "absolute", right: "5px", cursor: "pointer" }}
            >
              <img className=" h-100 text-end" src={wrong} alt="" />
            </div>
            {props?.state && props?.state ? (
              <>
                <div className="d-flex justify-content-center flex-column pt-4">
                  <h4 className="text-center pt-2 text-white fw-bold pb-3">
                    Update Request Details
                  </h4>
                  <button
                    style={{
                      width: "250px",
                      backgroundColor: "#fff",
                      padding: "10px",
                    }}
                    className="modalSubmitBtn mb-3 fs-5 fw-bold text-black"
                    onClick={() => {
                      props.yesOnClick();
                    }}
                  >
                    {props.isLoading ? (
                      <div className="spinner-border text-black" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Click Here To Update"
                    )}
                  </button>
                </div>
              </>
            ) : (
              <>
                <h4 className="text-center pt-3 text-white fw-bold">
                  {/* Please confirm the payment method */}
                  Submit Request Details
                </h4>
                <div className="pt-4">
                  {/* <button
                    className="modalSubmitBtn mb-3 fs-5 me-4 fw-bold text-black"
                    style={{ width: "250px", backgroundColor: "#fff" }}
                    onClick={() => {
                      // props.yesOnClick();
                      setIsOpen(true);
                      props.setDeleteConfirmAlert(false);
                      // handleClose();
                      // props?.setOpen(false);
                    }}
                  >
                    {props.isLoading ? (
                      <div className="spinner-border text-black" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Service Before Payment"
                    )}
                  </button> */}
                  <button
                    style={{
                      width: "250px",
                      backgroundColor: "#fff",
                      height: "45px",
                    }}
                    className="modalSubmitBtn mb-3 fs-5 fw-bold text-black"
                    onClick={() => {
                      props.yesOnClick();
                    }}
                  >
                    {props.isLoading ? (
                      <div className="spinner-border text-black" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      // "Service After Paymen
                      "Submit"
                    )}
                  </button>
                </div>
              </>
            )}
          </div>
        </Box>
      </Modal>
      <Modal
        open={props.deleteSuccessfully}
        onClose={props.handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          className="d-flex flex-column justify-content-between align-items-center"
          sx={{ ...style, width: 400, height: 180, borderRadius: 6 }}
        >
          <h4 className="text-center pt-3">
            Deleted Successfully
            {/* {props.UpdateOpen
              ? "Data Updated Successfully"
              : "Data Added Successfully"} */}
          </h4>
          <button
            className="modalSubmitBtn mb-3 fs-5"
            onClick={() => {
              // props.setDeleteConfirmAlert(false)
              props.setDeleteSuccessfully(false);
            }}
          >
            OK
          </button>
        </Box>
      </Modal>
      <Modal
        open={props.errorOpen}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          className="d-flex flex-column justify-content-between align-items-center"
          sx={{ ...style, width: 700, height: 150, borderRadius: 6 }}
        >
          <h5 className="text-center pt-3">
            {props.errorOpen}
            {/* Data Deleted Successfully */}
            {/* {props.UpdateOpen
              ? "Data Updated Successfully"
              : "Data Added Successfully"} */}
          </h5>
          <button
            className="modalSubmitBtn mb-3 fs-5"
            onClick={() => {
              // props.setDeleteConfirmAlert(false)
              props.setErrorOpen(false);
            }}
          >
            OK
          </button>
        </Box>
      </Modal>
      <PopupBox
        isOpen={isOpen}
        onClose={closeModal}
        formData={props?.formData}
      />
    </div>
  );
}
