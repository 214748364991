import React, { useEffect, useState } from "react";
import InputField from "../common/components/inputfield/InputField";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { useMutation } from "react-query";
import addIcon from "../common/assets/images/add_circle.svg";
import wrong from "../common/assets/images/wrong.svg";
import axios from "axios";
import { WithContext as ReactTags } from "react-tag-input";
import AlertModal from "../common/components/popupmodel/AlertModal";
import deleteIcon from "../common/assets/images/delete-black.png";
import PopupModel from "../common/components/popupmodel/PopupModel";

export default function AddProduct() {
  const [productType, setProductType] = useState("");

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [base64Image, setBase64Image] = useState({});

  // Function to get the current system time in HH:mm format
  const getCurrentTime = () => {
    const now = new Date();
    return now.toTimeString().slice(0, 5); // Format time as HH:mm (e.g., "14:30")
  };

  // State to hold the current time from system
  const [currentTime, setCurrentTime] = useState(getCurrentTime());
  const { control, handleSubmit, setValue, watch, register } = useForm({
    defaultValues: {
      selectSlot: currentTime,
    },
  });
  // Update time every minute
  useEffect(() => {
    const interval = setInterval(() => {
      const newTime = getCurrentTime();
      setCurrentTime(newTime);
      setValue("selectSlot", newTime);
    }, 60000); // Update every minute (60000ms)

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [setValue]);
  // const [base64Image, setBase64Image] = useState(null);
  const [tags, setTags] = useState([]);
  const [open, setOpen] = useState(false);
  const [UpdateOpen, setUpdateOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const { state } = useLocation();

  const { userForm, subProduct, service } = watch();
  const tagsTextArray = tags?.map((item) => item?.text);
  const userFormContainerArray = userForm?.map((item) => item?.complaint);
  // console.log("userFormContainerArray", userFormContainerArray);

  const { fields, append, remove } = useFieldArray({
    name: "userForm",
    control,
  });

  // Convert file to Base64 string without metadata
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        let base64String = reader.result; // Get Base64 string
        // Remove the prefix (data:image/jpeg;base64, or similar)
        const base64Data = base64String.split(",")[1]; // This removes the metadata part
        resolve(base64Data); // Return only the Base64 data (no metadata)
      };
      reader.onerror = reject;
      reader.readAsDataURL(file); // Read file as Base64 string
    });
  };

  // Multiple file Upload

  const handleFileChange = (onChange) => async (e) => {
    const files = Array.from(e.target.files); // Convert FileList to array
    setSelectedFiles((prevFiles) => {
      const newFiles = [...prevFiles, ...files]; // Append new files to the existing list
      return newFiles;
    });

    // Convert each file to Base64 and update form state
    const base64Files = await Promise?.all(
      files?.map((file) => convertToBase64(file))
    );

    // Get the current documentUploads field value (existing Base64 files)
    const currentBase64Data = watch("photoFile") || [];

    // Update form state by appending new Base64 data to the existing array
    onChange([...currentBase64Data, ...base64Files]);
  };

  // Remove a file from the list
  const removeFile = async (index, onChange) => {
    // Remove file from selectedFiles
    const newSelectedFiles = selectedFiles?.filter((_, i) => i !== index);
    setSelectedFiles(newSelectedFiles);

    // Convert the remaining files to Base64
    const remainingBase64Files = await Promise.all(
      newSelectedFiles?.map((file) => convertToBase64(file))
    );

    // Directly update the form state with the remaining Base64 files
    onChange(remainingBase64Files); // Update form state directly with the new Base64 data
  };

  // console.log("STATE", state);
  useEffect(() => {
    setValue("product", service);
    if (state?.id) {
      setValue("service", state?.service);
      setValue("product", state?.product);
      // setValue("subProduct", state?.subProduct);
      setValue("productType", state?.productType);
      setValue("brandName", state?.brandName);
      setValue("modelName", state?.modelName);
      if (state?.subProduct) {
        const subProductTags = state?.subProduct?.map((item) => ({
          id: item, // Assuming each `item` is a string, use `id` for ReactTags
          text: item, // `text` is what ReactTags displays
        }));

        setTags(subProductTags); // Update the `tags` state used by ReactTags
        setValue(
          "subProduct",
          subProductTags?.map((tag) => tag.text)
        ); // Set form field value
      }
      if (state?.complaint) {
        const complaintData = state?.complaint?.map((complaint) => ({
          complaint: complaint,
        }));

        setValue("userForm", complaintData);
      }
    }
  }, [state, setValue, service]);

  const { mutate, isLoading, isError, isSuccess, reset } = useMutation(
    async (postData) => {
      try {
        console.log("postData", postData);
        const response = await axios.post(
          `${process.env.REACT_APP_HOST}/products`,
          // "https://api.2kvirtualworld.com/CRM/technicians",
          postData
        );
        // reset()
        setOpen("PRODUCT ADDED");
        return response.data;
      } catch (error) {
        console.error("Error in mutation:", error);

        throw error;
      }
    }
  );

  const UpdateFormValue = useMutation(
    async (postData) => {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_HOST}/products/${state?.id}`,
          postData
        );
        return response.data;
      } catch (error) {
        console.error("Error updating form:", error);
        throw error; // Re-throw the error to trigger the `onError` callback
      }
    },
    {
      onSuccess: (data) => {
        // Handle success
        console.log("Successfully updated:", data);
        setUpdateOpen("PRODUCT UPDATED");

        // Navigate("/viewUserManagement");
      },
      onError: (error) => {
        // Handle error
        console.error("Mutation failed:", error);
        alert(`Update failed: ${error.message}`); // Optional user feedback
      },
    }
  );

  // console.log("userForm", userForm);

  const Submit = async (data) => {
    // console.log("data", data);
    const formValue = {
      service: data?.service,
      product: data?.product,
      modelName: data?.modelName,
      subProduct: tagsTextArray,
      photo: data?.photoFile,
      complaint: userFormContainerArray,
      totalAmount: data?.totalAmt,
      actualAmount: "",
      netAmount: data?.netAmt,
      technicianShare: data?.technicianShare,
      ownerShare: data?.ownerShare,
      brandName: data?.brandName,
      productType: data?.productType,
    };

    if (state && state?.id) {
      UpdateFormValue.mutate(formValue);
    } else {
      mutate(formValue);
    }
    // handleImageInputChange(data?.aadharFile);
  };
  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  // const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };
  const handleAddition = (tag) => {
    // console.log('demo',tag.);

    setTags([...tags, tag]);
  };
  const handleDrag = (tag, currPos, newPos) => {
    const newTags = [...tags];
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setTags(newTags);
  };
  return (
    <div className="w-100 p-3 px-4 ">
      <div
        className="bg-white px-3"
        style={{
          height: "95vh",
          borderRadius: "10px",
          overflowY: "scroll",
          scrollbarWidth: "thin",
        }}
      >
        <header
          className="fs-2 fw-bold py-2 ps-4  text-center"
          style={{
            height: "10%",
            position: "sticky",
            top: "0px",
            backgroundColor: "#fff",
            // border:"1px solid red"
          }}
        >
          {state && state ? <>EDIT PRODUCT </> : <>ADD PRODUCT</>}
        </header>
        <section>
          <form onSubmit={handleSubmit(Submit)}>
            <div className="addUser_ticket_label">
              <h4 className="fw-bold addrequest-heading pt-4">
                {" "}
                Product Details
              </h4>
              <div className=" col-12  d-flex mt-4 mb-3">
                <div className="col-6">
                  <label className="col-5 addUser_ticket_label">
                    {/* Service */}
                    Product
                  </label>
                  <Controller
                    control={control}
                    name="service"
                    render={(field) => (
                      <InputField
                        addUserInputCss="--addUser_inputFields"
                        {...field}
                        type="text"
                      />
                    )}
                    // rules={{ required: true }}
                  />
                </div>
                <div className="col-6" style={{ display: "none" }}>
                  <label className="col-5 addUser_ticket_label">Product</label>
                  <Controller
                    control={control}
                    name="product"
                    render={(field) => (
                      <InputField
                        addUserInputCss=" --addUser_inputFields"
                        {...field}
                        type="text"
                      />
                    )}
                  />
                </div>
                {/* <div className="col-6">
                  <label className="col-5 addUser_ticket_label">
                    Product Type
                  </label>
                  <Controller
                    control={control}
                    name="productType"
                    render={(field) => (
                      <InputField
                        addUserInputCss=" --addUser_inputFields"
                        {...field}
                        type="text"
                      />
                    )}
                  />
                </div> */}
              </div>

              <div className="col-12 my-3">
                <div className="d-flex ">
                  <label
                    style={{ width: "20%" }}
                    className="addUser_ticket_label"
                  >
                    {/* Sub Product */}
                    Product Type
                  </label>
                  <div
                    style={{
                      width: "75%",
                      backgroundColor: "#004BCB66",
                      marginLeft: "8px",
                      borderRadius: "6px",
                    }}
                  >
                    <Controller
                      control={control}
                      name="subProduct"
                      render={(field) => (
                        <ReactTags
                          handleDelete={handleDelete}
                          handleAddition={handleAddition}
                          handleDrag={handleDrag}
                          tags={tags}
                          placeholder=""
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className=" col-12 my-3 d-flex">
                <div className="col-6">
                  <label className="col-5 addUser_ticket_label">
                    Brand Name
                  </label>
                  <Controller
                    control={control}
                    name="brandName"
                    render={(field) => (
                      <InputField
                        addUserInputCss=" --addUser_inputFields"
                        {...field}
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="col-6">
                  <label className="col-5 addUser_ticket_label">
                    Model Name
                  </label>
                  <Controller
                    control={control}
                    name="modelName"
                    render={(field) => (
                      <InputField
                        addUserInputCss=" --addUser_inputFields"
                        {...field}
                        type="text"
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 my-3">
              <div className="d-flex">
                <label
                  style={{ width: "20%" }}
                  className="addUser_ticket_label"
                >
                  Photo
                </label>

                <label
                  for="photoImg"
                  className="col-8 ms-2 aadharFileInput text-center d-flex justify-content-center align-items-center "
                >
                  UPLOAD A FILE HERE{" "}
                  <img className="h-75" src={addIcon} alt="" />
                </label>
              </div>
              <div>
                {selectedFiles && selectedFiles?.length > 0 && (
                  <ul>
                    {selectedFiles?.map((file, index) => (
                      <li key={index}>
                        {file?.name}
                        <button
                          type="button"
                          onClick={() =>
                            removeFile(index, (newData) =>
                              setValue("photoFile", newData)
                            )
                          } // Correct removeFile call
                          style={{
                            marginLeft: "10px",
                            cursor: "pointer",
                            border: "none",
                            color: "red",
                          }}
                        >
                          X
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
                {state &&
                  state?.photo?.length > 0 &&
                  state?.photo?.map((data, index) => {
                    return (
                      <>
                        <img
                          key={index}
                          src={`data:image/jpeg;base64,${data}`}
                          alt="no-image"
                          style={{
                            width: "60px",
                            height: "60px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            // openModal(data);
                          }}
                        />
                        {/* src={`data:image/jpeg;base64,${image}`} */}
                      </>
                    );
                  })}
              </div>

              <Controller
                name="photoFile"
                control={control}
                defaultValue={[]}
                render={({ field: { onChange } }) => (
                  <div>
                    <input
                      type="file"
                      id="photoImg"
                      accept="*/*"
                      multiple
                      onChange={handleFileChange(onChange)} // Handle file change
                      style={{ display: "none" }} // Hide the default file input
                    />
                  </div>
                )}
              />
            </div>

            <div className="col-12 my-3">
              <div className="d-flex">
                <label
                  style={{ width: "20%" }}
                  className="addUser_ticket_label"
                >
                  Complaint
                </label>

                <div className="col-8">
                  {fields?.map((field, index) => {
                    return (
                      <div key={field.id}>
                        <div className="d-flex">
                          <input
                            className="col-12 mt-2 fs-5 text-center ms-2 aadharFileInput"
                            {...register(`userForm.${index}.complaint`)}
                            placeholder={`Enter complaint ${index + 1}`}
                            // defaultValue={field.productNam..e} // Important for default values
                          />
                          <img
                            {...register(`userForm.${index}.image`)}
                            onClick={() => {
                              console.log("index", index);
                              remove(index);
                            }}
                            className=" mt-2 ps-2 "
                            src={wrong}
                            alt=""
                          />
                        </div>
                      </div>
                    );
                  })}
                  <div
                    className=" col-12 fs-5 text-center ms-2 aadharFileInput my-3"
                    onClick={() => {
                      append({ complaint: "" });
                    }}
                  >
                    Add Complaint
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 mt-4 addUser_submitBtn_field">
              <button className="col-5 addUser_submitBtn my-4" type="submit">
                {isLoading ? (
                  <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "SUBMIT"
                )}
              </button>
            </div>
          </form>
        </section>
      </div>
      <AlertModal
        open={open}
        setOpen={setOpen}
        setUpdateOpen={setUpdateOpen}
        UpdateOpen={UpdateOpen}
        navigate={"/viewProduct"}
        setErrorOpen={setErrorOpen}
        errorOpen={errorOpen}
      />
    </div>
  );
}
