import axios from "axios";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import downloadIcon from "../../assets/images/download.png";
import { useNavigate } from "react-router-dom";
import PopupModel from "../popupmodel/PopupModel";
import editIcon from "../../assets/images/editIcon.png";
import UserPic from "../../assets/images/userpic.png";
import deleteIcon from "../../assets/images/delete.png";
import logo from "../../assets/images/2k-logo.png";
import sandtimer from "../../assets/images/sandtimer.png";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
const ViewProductComp = () => {
  const navigate = useNavigate();
  const getModelList = async () => {
    const response = await axios.get(
      // `https://api.2kvirtualworld.com/CRM/requests`
      // `${process.env.REACT_APP_HOST}/products/names`
      `${process.env.REACT_APP_HOST}/products`
    );
    return response.data; // Ensure we return the actual data from the response
  };
  const {
    data: modelData,
    isError,
    isLoading,
    refetch,
  } = useQuery("getModelList", getModelList);

  const [technicianId, setTechnicianId] = useState("");

  const [popupState, setPopupState] = useState({
    isOpen: false,
    dltpopup: false,
    type: null,
    data: null,
  });

  // Open the details popup
  const handleOpenDetailsPopup = (item) => {
    setPopupState({ isOpen: true, type: "details", data: item });
    setTechnicianId(item?.id);
  };
  // Open delete confirmation popup
  const handleDeleteClick = () => {
    setPopupState({
      isOpen: true,
      type: "deleteConfirmation",
      data: null,
      dltpopup: true,
    });
  };
  const deleteItem = async (id) => {
    await axios
      .delete(`${process.env.REACT_APP_HOST}/products/${id}`)
      .then((data) => {
        console.log("data deleted..");
        // refetch();
      })
      .catch((error) => {
        console.log("error occur data not deleted..");
      });
  };

  // Mutation to handle technician deletion
  const mutation = useMutation(deleteItem, {
    onSuccess: () => {
      refetch();
      setPopupState({ isOpen: true, type: "success", data: null });
    },
    onError: (err) => {
      console.error("Error deleting technician:", err);
    },
  });

  // Confirm the deletion
  const handleDeleteConfirmation = () => {
    if (technicianId) {
      mutation.mutate(technicianId);
      setPopupState({ isOpen: false, type: "success", data: null });
    }
  };
  // Close all popups
  const handleCloseAllPopups = () => {
    setPopupState({ isOpen: false, type: null, data: null });
  };

  // Function to handle Excel download
  const downloadExcel = () => {
    // Determine data to export based on filter
    const exportData =
      modelData && modelData?.length > 0 ? modelData : modelData;

    // Prepare data for the table worksheet
    const tableDatas = exportData?.map((data, index) => ({
      "PRODUCT ID": data?.id || "NULL",
      PRODUCT: data?.product || "NULL",
      "PRODUCT TYPE":
        data?.subProduct
          ?.map((d, i) => {
            return d;
          })
          .join(",") || "NULL",
      "BRAND NAME": data?.brandName || "NULL",
      "MODEL NAME": data?.modelName || "NULL",
      COMPLAINT:
        data?.complaint
          ?.map((d, i) => {
            return d;
          })
          .join(",") || "NULL",
    }));

    // Create table worksheet with headers
    const tableWorksheet = XLSX.utils.json_to_sheet(tableDatas, {
      header: [
        "PRODUCT ID",
        "PRODUCT",
        "PRODUCT TYPE",
        "BRAND NAME",
        "MODEL NAME",
        "COMPLAINT",
      ],
      skipHeader: false, // Ensure the header is included
    });

    // Create a new workbook and add the worksheets
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, tableWorksheet, "Table Data");

    // Generate the Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Save the Excel file
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "View Product.xlsx");
  };
  return (
    <>
      <div className="overview-main-block my-4">
        <div className="overview-heading-block">
          <h6 className="overview-head">VIEW PRODUCT</h6>
          <div className="overview-search-block px-3">
            <div
              className="overview-download-block product-download"
              onClick={() => downloadExcel()}
            >
              <div className="search-icon">
                <img src={downloadIcon} alt="no-image" className="crm-image" />
              </div>
              <button className="overview-btn">DOWNLOAD</button>
            </div>
          </div>
        </div>
        <div className="overview-table-main-block">
          <div className="table-responsive table-container">
            <table className="table overview-table">
              <thead className="table-heading">
                <tr>
                  <th className="technicians-table-head">Service</th>
                  <th className="technicians-table-head">Product Type</th>
                  <th className="technicians-table-head">Brand Name</th>
                  <th className="technicians-table-head">Model Name</th>
                  {/* <th className="technicians-table-head">Photo</th> */}
                  <th className="technicians-table-head">Complaint</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="8" className="text-center">
                      Loading...
                    </td>
                  </tr>
                ) : isError ? (
                  <tr>
                    <td colSpan="8" className="text-center text-danger">
                      No Network...
                    </td>
                  </tr>
                ) : modelData && modelData?.length > 0 ? (
                  modelData?.map((item, i) => {
                    return (
                      <>
                        <tr
                          key={item?.id}
                          onClick={() => handleOpenDetailsPopup(item)}
                          style={{ cursor: "pointer" }}
                        >
                          <td className="technicians-table-data">
                            {item?.service || ""}
                          </td>
                          <td className="technicians-table-data">
                            {item?.subProduct?.join(", ") || ""}
                          </td>
                          <td className="technicians-table-data">
                            {item?.brandName || ""}
                          </td>
                          <td className="technicians-table-data">
                            {item?.modelName || ""}
                          </td>
                          <td className="technicians-table-data">
                            {item?.complaint?.join(", ") || ""}
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td colSpan="8" className="text-center">
                        No Records Found
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <PopupModel
        isOpen={popupState.isOpen && popupState.type === "details"}
        onClose={handleCloseAllPopups}
        backgroundColor="#f9f9f9"
        maxWidth="500px"
      >
        {popupState?.data && (
          <div className="popup-box">
            <div className="request-details --product-delete-popup">
              <div className="request-user-details mt-4">
                <p className="request-key">PRODUCT ID</p>{" "}
                <p className="request-value">: {popupState?.data?.id}</p>
              </div>
              <div className="request-user-details">
                <p className="request-key">SERVICE</p>{" "}
                <p className="request-value">: {popupState?.data?.service}</p>
              </div>
              <div className="request-user-details">
                <p className="request-key">PRODUCT TYPE </p>{" "}
                <p className="request-value">
                  {" "}
                  :{" "}
                  {popupState?.data?.subProduct
                    ?.map((d, i) => {
                      return d;
                    })
                    .join(" ,")}{" "}
                </p>
              </div>
              <div className="request-user-details">
                <p className="request-key">BRAND NAME </p>{" "}
                <p className="request-value">
                  {" "}
                  : {popupState?.data?.brandName}
                </p>
              </div>
              <div className="request-user-details">
                <p className="request-key">MODEL NAME</p>{" "}
                <p className="request-value">: {popupState?.data?.modelName}</p>
              </div>
              <div className="request-user-details">
                <p className="request-key">COMPLAINT </p>{" "}
                <p className="request-value">
                  :{" "}
                  {popupState?.data?.complaint
                    ?.map((d, i) => {
                      return d;
                    })
                    .join(" ,")}
                </p>
              </div>
              <div className="mt-3  mb-2 d-flex justify-content-center gap-2">
                <div className="popup-delete" onClick={handleDeleteClick}>
                  <img src={deleteIcon} alt="no-image" className="crm-image" />
                </div>
                <div
                  className="popup-edit"
                  onClick={() => {
                    navigate("/addProduct", { state: popupState?.data });
                  }}
                >
                  <img src={editIcon} alt="no-image" className="crm-image" />
                </div>
              </div>
            </div>
          </div>
        )}
      </PopupModel>
      <PopupModel
        isOpen={popupState.dltpopup && popupState.type === "deleteConfirmation"}
        onClose={handleCloseAllPopups}
        backgroundColor="#004bcb"
        maxWidth="350px"
        onConfirm={handleDeleteConfirmation}
      >
        <div className="delete-popup-block">
          <p className="delete-content">Are You Sure</p>
          <div className="d-flex gap-2 my-2">
            <button
              className="delete-popup-btn"
              onClick={() => {
                handleDeleteConfirmation();
              }}
            >
              OK
            </button>
            <button
              className="delete-popup-btn"
              onClick={() => {
                handleCloseAllPopups();
              }}
            >
              CANCEL
            </button>
          </div>
        </div>
      </PopupModel>

      <PopupModel
        isOpen={popupState.isOpen && popupState.type === "success"}
        onClose={handleCloseAllPopups}
        maxWidth="350px"
        backgroundColor="#d4edda"
      >
        <div className="delete-sub-popup px-3 py-2">
          <p className="delete-sub-popup-content">
            Product ID has been Deleted
          </p>
          {/* <img src={sandtimer} /> */}
          <button
            className="delete-sub-popup-btn"
            onClick={() => {
              handleCloseAllPopups();
            }}
          >
            Done
          </button>
        </div>
      </PopupModel>
    </>
  );
};

export default ViewProductComp;
